import React, { useState, useEffect, useRef, useCallback } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useHistory, Link, NavLink, useLocation } from 'react-router-dom';
import { authLogout, HAS_BRANCH } from '../redux/auth/authAction';
import { toggleModal } from '../redux/common/common.actions';
import {
  Layout,
  Menu,
  Dropdown,
  Button,
  Form,
  Input,
  message,
  Spin,
  Switch,
} from 'antd';
import { SVGIcon } from '../../src/components/SVGIcon/SVGIcon';
import { useTranslation } from 'react-i18next';
import { useCan, getterState } from '../utils/getter';
import { StorageService } from '../services/storage.service';
import { debounce } from '../utils/debounce';
import {
  SET_APPLICATION_QUERY_PARAM,
  SET_ORDER_QUERY_PARAM,
} from '../redux/orders/orders.actions';
import { SET_BOX_QUERY_PARAM } from '../redux/boxes/boxes.actions';
import { SET_FLIGHT_QUERY_PARAM } from '../redux/flights/flights.actions';
import { SET_CLIENT_QUERY_PARAM } from '../redux/clients/client.actions';
import { SET_BATCH_QUERY_PARAM } from '../redux/warehouses/warehouses.actions';
import { SET_CATEGORY_QUERY_PARAM } from '../redux/categories/categories.actions';
import { AuthService } from '../services/auth.service';
import { TextComponent } from './Text';
import {
  INTERN_OPERATOR_ROLE,
  INTERN_ROLE,
  MODERATOR_ROLE,
  OPERATOR_ROLE,
  SUPER_ADMIN_ROLE,
  COURIER_ROLE,
    MERCHANT_ROLE
} from '../constants/role';
import { SET_TASK_QUERY_PARAM } from '../redux/tasks/tasks.actions';
import { SET_TRANSACTION_QUERY_PARAM } from '../redux/payments/payment.actions';
import { ChangePaging } from '../redux/siteSetting/site.actions';
import { SET_CALLS_QUERY_PARAM } from '../redux/calls/calls.actions';

const { Header } = Layout;

const NavbarMain = ({
  searchedPage,
  headerButton,
  authLogout,
  toggleModal,
  callConnected,
}) => {
  const [t] = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const user = useSelector(getterState('auth.user'));
  const hasBranch = useSelector(getterState('auth.hasBranch'));
  const pagingState = useSelector(state => state.site.pagingState);
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);
  const [changeBranchLoader, setChangeBranchLoader] = useState(false);
  const [cleared, setCleared] = useState(true);
  const [searchPlaceholder, setSearchPlaceholder] = useState(t('search'));
  const searchInputRef = useRef();
  const [navLinks, setNavLinks] = useState([]);

  // const canMiddle = useCan([OPERATOR_ROLE, MODERATOR_ROLE, SUPER_ADMIN_ROLE]);
  // const canSmall = useCan([MODERATOR_ROLE, SUPER_ADMIN_ROLE]);
  const canOperator = useCan([OPERATOR_ROLE]);
  const canSuperAdmin = useCan([SUPER_ADMIN_ROLE]);
  const canModerator = useCan([MODERATOR_ROLE]);
  const canCourier = useCan([COURIER_ROLE]);
  const canIntern = useCan([INTERN_ROLE]);
  const canInternOperator = useCan([INTERN_OPERATOR_ROLE]);
  const canMerchant = useCan([MERCHANT_ROLE]);
  const showModal = modalName => {
    return () => {
      toggleModal(modalName);
    };
  };

  const searchByPage = useCallback(
    searchedText => {
      if (searchedText?.length) {
        setCleared(false);
      } else {
        setCleared(true);
      }
      switch (searchedPage) {
        case 'boxes':
          dispatch({
            type: SET_BOX_QUERY_PARAM,
            payload: { 'filter[search]': searchedText, page: 1 },
          });
          break;
        case 'orders':
          dispatch({
            type: SET_ORDER_QUERY_PARAM,
            payload: { 'filter[search]': searchedText, page: 1 },
          });
          break;
        case 'calls':
          dispatch({
            type: SET_CALLS_QUERY_PARAM,
            payload: { 'filter[search]': searchedText, page: 1 },
          });
          break;
        case 'clients':
          dispatch({
            type: SET_CLIENT_QUERY_PARAM,
            payload: { 'filter[search]': searchedText, page: 1 },
          });
          break;
        case 'transactions':
          dispatch({
            type: SET_TRANSACTION_QUERY_PARAM,
            payload: { 'filter[search]': searchedText, page: 1 },
          });
          break;
        case 'warehouses':
          dispatch({
            type: SET_BATCH_QUERY_PARAM,
            payload: { 'filter[search]': searchedText, page: 1 },
          });
          break;
        case 'flights':
          dispatch({
            type: SET_FLIGHT_QUERY_PARAM,
            payload: { 'filter[search]': searchedText, page: 1 },
          });
          break;
        case 'applications':
          dispatch({
            type: SET_APPLICATION_QUERY_PARAM,
            payload: {
              'filter[search]': searchedText,
              'filter[receive_type]': undefined,
              'filter[status]': undefined,
            },
          });
          break;
        case 'application-show':
          dispatch({
            type: SET_APPLICATION_QUERY_PARAM,
            payload: {
              'filter[search]': searchedText,
              'filter[receive_type]': undefined,
              'filter[status]': undefined,
            },
          });
          history.push('/admin/applications');
          break;
        case 'tasks':
          dispatch({
            type: SET_TASK_QUERY_PARAM,
            payload: {
              search: searchedText,
              status_id: undefined,
            },
          });
          break;
        case 'tasks-show':
          dispatch({
            type: SET_TASK_QUERY_PARAM,
            payload: {
              search: searchedText,
              status_id: undefined,
            },
          });
          history.push('/admin/applications/tasks');
          break;
        // case 'branches':
        //   dispatch({ type: SET_ORDER_QUERY_PARAM, payload: { 'filter[search]': searchedText } })
        //   break
        case 'categories':
          dispatch({
            type: SET_CATEGORY_QUERY_PARAM,
            payload: { 'filter[search]': searchedText, page: 1 },
          });
          break;
        // case 'operators':
        //   dispatch({ type: SET_ORDER_QUERY_PARAM, payload: { 'filter[search]': searchedText } })
        //   break
        // case 'places':
        //   dispatch({ type: SET_ORDER_QUERY_PARAM, payload: { 'filter[search]': searchedText } })
        //   break
        // case 'offers':
        //   dispatch({ type: SET_ORDER_QUERY_PARAM, payload: { 'filter[search]': searchedText } })
        //   break
        // case 'sheets':
        //   dispatch({ type: SET_ORDER_QUERY_PARAM, payload: { 'filter[search]': searchedText } })
        //   break
        // case 'shops':
        //   dispatch({ type: SET_ORDER_QUERY_PARAM, payload: { 'filter[search]': searchedText } })
        //   break
        // case 'statuses':
        //   dispatch({ type: SET_ORDER_QUERY_PARAM, payload: { 'filter[search]': searchedText } })
        //   break
        default:
      }
    },
    [searchedPage],
  );

  const handleHeaderSearch = debounce(e => {
    const searchedText = form.getFieldValue('q');
    setLoader(true);
    setTimeout(() => {
      setLoader(false);
    }, 500);
    searchByPage(searchedText);
  }, 1000);

  const handleLogout = () => {
    localStorage.clear();
    authLogout();
    history.push('/');
  };

  const handleChangePaging = () => {
    dispatch(ChangePaging());
  };

  const menu = (
    <Menu>
      <Menu.Item onClick={handleLogout}>
        <Link rel="noopener noreferrer" to="#">
          <SVGIcon iconPath="quit" />
          {t('log_out')}
        </Link>
      </Menu.Item>
      <Menu.Item>
        <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
          <Switch checked={pagingState} onChange={handleChangePaging} />{' '}
          <h3 style={{ marginBottom: 4 }}>Pagination</h3>
        </div>
      </Menu.Item>
    </Menu>
  );

  const handleChangeBranch = branch => async () => {
    setChangeBranchLoader(true);
    try {
      StorageService.setBranch(branch.id);
      await AuthService.setUserBranch(branch);
      dispatch({ type: HAS_BRANCH, payload: branch });
      history.go(0);
    } catch (error) {
      if (error?.response?.status) {
        message.error(error?.response?.message || error?.response?.error);
      }
    } finally {
      setChangeBranchLoader(false);
    }
  };

  const branchesMenu = (
    <Menu>
      {user?.branches.map(branch => {
        if (branch.id !== hasBranch?.id) {
          return (
            <Menu.Item key={branch.id} onClick={handleChangeBranch(branch)}>
              {branch.name}
            </Menu.Item>
          );
        }
        return null;
      })}
    </Menu>
  );

  const handleClearSearch = () => {
    form.resetFields();
    searchByPage('');
  };

  const pagesNavLinks = {
    orders: {
      title: t('order_plural'),
      path: '/admin/orders',
      svgId: 'orders',
      svgActive: 'activeOrders',
    },
    applications: {
      title: t('application'),
      path: '/admin/applications',
      svgId: 'dashboard',
      svgActive: 'dashboard',
    },
    boxes: {
      title: t('boxes'),
      path: '/admin/boxes',
      svgId: 'boxes',
      svgActive: 'activeBox',
    },
    flights: {
      title: t('flights'),
      path: '/admin/flights',
      svgId: 'airplane',
      svgActive: 'activeAirplane',
    },
    clients: {
      title: t('client_plural'),
      path: '/admin/clients',
      svgId: 'user',
      svgActive: 'activeUser',
    },
    finance: {
      title: t('finance'),
      path: '/admin/finance',
      svgId: 'finance',
      svgActive: 'finance',
    },
    warehouses: {
      title: t('warehouse'),
      path: '/admin/warehouse',
      svgId: 'warehouse',
      svgActive: 'warehouse-active',
    },
    marketing: {
      title: t('marketing'),
      path: '/admin/marketing',
      svgId: 'location',
      svgActive: 'location',
    },
    delivery: {
      title: t('delivery'),
      path: '/admin/delivery',
      svgId: 'ship',
      svgActive: 'ship',
    },
    calls: {
      title: t('calls'),
      path: '/admin/calls',
      svgId: 'phone',
      svgActive: 'phone',
    },
    chat: {
      title: t('chat'),
      path: '/admin/chat',
      svgId: 'message',
      svgActive: 'message',
    },
    setting: {
      title: t('setting'),
      path: '/admin/settings/general',
      svgId: 'settings',
      svgActive: 'activeSettings',
    },
  };

  const toArrayPagesNav = (pages = []) => {
    return pages.reduce((acc, val) => {
      acc.push(pagesNavLinks[val]);
      return acc;
    }, []);
  };

  useEffect(() => {
    searchInputRef.current.focus();
    switch (searchedPage) {
      case 'boxes':
        setSearchPlaceholder(t('search_by', { name: 'ID, код каробки' }));
        break;
      case 'orders':
        setSearchPlaceholder(
          t('search_by', { name: '#️⃣заказ.н,❗клиент, @трк.ч,:трк.п' }),
        );
        break;
      case 'clients':
        setSearchPlaceholder(
          t('search_by', { name: 'ID, пасспорт серия, номер телефона' }),
        );
        break;
      case 'warehouses':
        setSearchPlaceholder(t('search_by', { name: 'ID, номер клиента' }));
        break;
      case 'flights':
        setSearchPlaceholder(t('search_by', { name: 'ID, номер рейса' }));
        break;
      case 'categories':
        setSearchPlaceholder(t('search_by', { name: 'Названиям' }));
        break;
      case 'applications':
        setSearchPlaceholder(t('search_by', { name: 'ID, Track-номер' }));
        break;
      case 'application-show':
        setSearchPlaceholder(t('search_by', { name: 'ID, Track-номер' }));
        break;
      case 'tasks':
        setSearchPlaceholder(t('search_by', { name: 'ID, Track-номер' }));
        break;
      case 'calls':
        setSearchPlaceholder(t('search_by', { name: 'Номер, Клиент' }));
        break;
      case 'tasks-show':
        setSearchPlaceholder(t('search_by', { name: 'ID, Track-номер' }));
        break;
      default:
        setSearchPlaceholder(t('search'));
    }
  }, [searchedPage]);

  useEffect(() => {
    const searchedText = form.getFieldValue('q');
    if (searchedText) {
      handleClearSearch();
    }
  }, [location]);

  useEffect(() => {
    if (user) {
      let base = [];
      if (canSuperAdmin || canModerator) {
        base = toArrayPagesNav([
          'applications',
          'orders',
          'boxes',
          'warehouses',
          'flights',
          'clients',
          'finance',
          'marketing',
          'delivery',
          'calls',
          'chat',
          'setting',
        ]);
      } else if (canOperator) {
        base = toArrayPagesNav([
          'applications',
          'orders',
          'boxes',
          'flights',
          'clients',
          'calls',
          'chat',
        ]);
      } else if (canCourier) {
        base = toArrayPagesNav(['orders', 'boxes', 'calls', 'chat']);
      } else if (canIntern) {
        base = toArrayPagesNav([
          'applications',
          'orders',
          'warehouses',
          'clients',
          'calls',
          'finance',
          'chat',
        ]);
      } else if (canInternOperator) {
        base = toArrayPagesNav(['orders', 'clients', 'calls', 'chat']);
      }

      else if (canMerchant) {
        base = toArrayPagesNav(['applications', 'orders', 'boxes', 'flights']);
      }
      // const baseRoutes = [
      //   {
      //     title: t('order_plural'),
      //     path: '/admin/orders',
      //     svgId: 'orders',
      //     svgActive: 'activeOrders',
      //   },
      // ];
      // if (canMiddle) {
      //   baseRoutes.splice(0, 0, {
      //     title: t('application'),
      //     path: '/admin/applications',
      //     svgId: 'dashboard',
      //     svgActive: 'dashboard',
      //   });
      //   baseRoutes.splice(2, 0, {
      //     title: t('boxes'),
      //     path: '/admin/boxes',
      //     svgId: 'boxes',
      //     svgActive: 'activeBox',
      //   });
      //   baseRoutes.splice(4, 0, {
      //     title: t('flights'),
      //     path: '/admin/flights',
      //     svgId: 'airplane',
      //     svgActive: 'activeAirplane',
      //   });
      //   baseRoutes.splice(5, 0, {
      //     title: t('client_plural'),
      //     path: '/admin/clients',
      //     svgId: 'user',
      //     svgActive: 'activeUser',
      //   });
      //   baseRoutes.splice(6, 0, {
      //     title: t('finance'),
      //     path: '/admin/finance',
      //     svgId: 'finance',
      //     svgActive: 'finance',
      //   });
      // }
      // if (canInternOperator) {
      //   baseRoutes.splice(5, 0, {
      //     title: t('client_plural'),
      //     path: '/admin/clients',
      //     svgId: 'user',
      //     svgActive: 'activeUser',
      //   });
      // }
      // if (canIntern) {
      //   baseRoutes.splice(0, 0, {
      //     title: t('application'),
      //     path: '/admin/applications',
      //     svgId: 'dashboard',
      //     svgActive: 'dashboard',
      //   });
      //   baseRoutes.splice(3, 0, {
      //     title: t('warehouse'),
      //     path: '/admin/warehouse',
      //     svgId: 'warehouse',
      //     svgActive: 'warehouse-active',
      //   });
      //   baseRoutes.splice(5, 0, {
      //     title: t('client_plural'),
      //     path: '/admin/clients',
      //     svgId: 'user',
      //     svgActive: 'activeUser',
      //   });
      //   baseRoutes.splice(6, 0, {
      //     title: t('finance'),
      //     path: '/admin/finance',
      //     svgId: 'finance',
      //     svgActive: 'finance',
      //   });
      // }
      // if (canSmall) {
      //   baseRoutes.splice(2, 0, {
      //     title: t('boxes'),
      //     path: '/admin/boxes',
      //     svgId: 'boxes',
      //     svgActive: 'activeBox',
      //   });
      //   baseRoutes.splice(3, 0, {
      //     title: t('warehouse'),
      //     path: '/admin/warehouse',
      //     svgId: 'warehouse',
      //     svgActive: 'warehouse-active',
      //   });
      //   baseRoutes.splice(7, 0, {
      //     title: t('marketing'),
      //     path: '/admin/marketing',
      //     svgId: 'location',
      //     svgActive: 'location',
      //   });
      //   baseRoutes.splice(8, 0, {
      //     title: t('setting'),
      //     path: '/admin/settings/general',
      //     svgId: 'settings',
      //     svgActive: 'activeSettings',
      //   });
      // }
      setNavLinks(base);
    }
  }, [
    user,
    canIntern,
    canInternOperator,
    canSuperAdmin,
    canModerator,
    canOperator,
    canCourier,
  ]);

  return (
    <Layout>
      <Header className="navbar">
        {/*<div className="logoPlace">*/}
        {/*  <SVGIcon className="logo" iconPath="logo" />*/}
        {/*</div>*/}
        <Form className="formSearch" form={form}>
          <div className="inputsSearch">
            <SVGIcon
              iconPath="search"
              onClick={() => searchInputRef.current.focus()}
            />
            <Form.Item name="q">
              <Spin spinning={loader}>
                <Input
                  placeholder={searchPlaceholder}
                  onChange={handleHeaderSearch}
                  type="search"
                  ref={searchInputRef}
                  autoFocus={true}
                />
                {!cleared && (
                  <SVGIcon
                    iconPath="close"
                    className="inputsSearch__closable"
                    onClick={handleClearSearch}
                  />
                )}
              </Spin>
            </Form.Item>
          </div>
        </Form>
        <Menu mode="horizontal" id="basic-navbar-nav">
          {navLinks.map((link, index) => {
            return (
              <Menu.Item key={index}>
                <NavLink to={link.path} activeClassName="activeLink">
                  {location.pathname === link.path ? (
                    <SVGIcon className="activeSvg" iconPath={link.svgActive} />
                  ) : (
                    <SVGIcon className="defaultSvg" iconPath={link.svgId} />
                  )}
                  {link.title}
                </NavLink>
              </Menu.Item>
            );
          })}
        </Menu>
        <div className="userInfo">
          {headerButton.modalName && (
            <Button
              className="buttonAdd"
              type="primary"
              onClick={showModal(headerButton.modalName)}
            >
              <SVGIcon iconPath="plus" />
              <p className="name">{t('add')}</p>
            </Button>
          )}
          <div className="userName">
            <Dropdown
              overlay={branchesMenu}
              placement="bottomRight"
              arrow
              trigger={['click']}
            >
              <Button loading={changeBranchLoader}>
                {hasBranch?.name}
                <SVGIcon iconPath="arrowDown" className="arDown" />
              </Button>
            </Dropdown>
          </div>
          <div className="notification activeNotification">
            <SVGIcon iconPath="notification" />
          </div>
          {/* <img src={userAvatar} alt="avatar" /> */}
          <div className="userName">
            <Dropdown
              overlay={menu}
              placement="bottomRight"
              arrow
              trigger={['click']}
            >
              <Button
                className={
                  callConnected ? 'userName-active' : 'userName-inactive'
                }
              >
                <TextComponent
                  value={[user?.first_name, user?.last_name]}
                  separator={' '}
                />
                <SVGIcon iconPath="arrowDown" className="arDown" />
              </Button>
            </Dropdown>
          </div>
        </div>
      </Header>
    </Layout>
  );
};

const mapStateToProps = state => {
  return {
    headerButton: state.common.headerButton,
    searchedPage: state.common.searchedPage,
    callConnected: state.call.connected,
  };
};

export default connect(mapStateToProps, { authLogout, toggleModal })(
  NavbarMain,
);
