import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Routes } from './routes/routes';
import { getterState } from './utils/getter';
import { objectToQueryString } from './utils/query-string';
import Call from './pages/Call';
import { handleMinModal, initializeSocket } from './redux/call/call.actions';
import { PhoneOutlined } from '@ant-design/icons';

export const App = withRouter(({ history }) => {
  const dispatch = useDispatch();
  // const data = useSelector(state => state.call);
  const minModal = useSelector(state => state.call.minModal);

  useEffect(() => {
    dispatch(initializeSocket());
  }, [dispatch]);

  const loggedIn = useSelector(getterState('auth.loggedIn'));
  useEffect(() => {
    if (!loggedIn) {
      const search = objectToQueryString({
        redirectTo:
          history.location.pathname !== '/login'
            ? history.location.pathname
            : '',
      });
      history.push({
        pathname: '/login',
        search,
      });
    }
  }, [loggedIn]);

  const handleCallModal = () => {
    handleMinModal(false)(dispatch);
  };

  return (
    <div className="App">
      <Routes />

      <Call />
      {minModal && (
        <button className="floating-button" onClick={handleCallModal}>
          <div class="iconPing"></div>
          <div class="iconPing ping2"></div>
          <div class="iconPing ping3"></div>
          <div class="mapIcon">
            <PhoneOutlined />
          </div>
        </button>
      )}
    </div>
  );
});
